import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/service.css';


const Services1 = () => (
  
    <section id="what-we-do">
    	<div className="container-fluid">
			<p className="revolight-us">Your digital transformation journey.</p>
			<div className="row mt-5">
				<div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<div className="card">
						<div className="card-block block-1">
							<h5 className="card-title">Amazon Web Service</h5>
              <p className="card-text RevolightUs1" >We can help you with AWS journey regardless of the stage of the project </p>
							<a href="/" title="Read more" className="read-more" >Read more<i className="fa fa-angle-double-right ml-2"></i></a>
						</div>
					</div>
				</div>
				<div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<div className="card">
						<div className="card-block block-2">
							<h5 className="card-title">Google Cloud Platform</h5>
              <p className="card-text RevolightUs1">We can help you with GCP journey regardless of the stage of the project </p>
							<a href="/" title="Read more" className="read-more" >Read more<i className="fa fa-angle-double-right ml-2"></i></a>
						</div>
					</div>
				</div>
				<div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<div className="card">
						<div className="card-block block-3">
							<h5 className="card-title">Microsoft Azure</h5>
              <p className="card-text RevolightUs1">We can help you with Azure journey regardless of the stage of the project </p>
							<a href="/" title="Read more" className="read-more" >Read more<i className="fa fa-angle-double-right ml-2"></i></a>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<div className="card">
						<div className="card-block block-4">
							<h5 className="card-title">DevOps</h5>
							<p className="card-text RevolightUs1 ">DevOps is built in our DNA, We can help you with your DevOps cultural transformation</p>
							<a href="/" title="Read more" className="read-more" >Read more<i className="fa fa-angle-double-right ml-2"></i></a>
						</div>
					</div>
				</div>
				<div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<div className="card">
						<div className="card-block block-5">
							<h5 className="card-title">Software development</h5>
							<p className="card-text RevolightUs1">We can help you break down your Monoliths into microservices or build microservices from scrath</p>
							<a href="/" title="Read more" className="read-more" >Read more<i className="fa fa-angle-double-right ml-2"></i></a>
						</div>
					</div>
				</div>
				<div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
					<div className="card">
						<div className="card-block block-6">
							<h5 className="card-title">Training</h5>
							<p className="card-text RevolightUs1">We can help accelerate your learning and allow company stay up to date with modern solutions</p>
							<a href="/" title="Read more" className="read-more" >Read more<i className="fa fa-angle-double-right ml-2"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>	
	</section>
  
);

export default Services1;







