import React from "react"
import { Container } from "react-bootstrap"

import "../css/aboutUs.css";

const RevoligtAboutUs = () => (
  <Container className="RevolightUs">
    <div>
      <h1 className="revolight-us">Professional IT consultants and Software Services</h1>
      <h2 className="revolight-us-1">Through latest technologies and modern practices, we accelerate customer digital transformations</h2>
    </div>
    <br></br>
    <div>
      Over 10 years of geniune experience in consulting, training and working major companies from different industries both in private and public sectors, we have been engaged through latest technologies and modern practices to leverage and accelerate our customer digital transformation. Our drive is fueled by the future and be a major contributor of tomorrow future
    </div>
  </Container>

)

export default RevoligtAboutUs