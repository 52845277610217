import React from 'react';
import styled, { css } from 'styled-components';
import media from '../utils/style';

import { graphql } from 'gatsby'

import SEO from 'components/seo';
import Layout from 'components/layout';
import RevoligtAboutUs from "components/aboutUs"
import Client1 from '../components/client1';
import Services1 from '../components/service1'
import Certs from '../components/certs'
import Contact from "../components/contact1"



const Section = styled.div`
  text-align: center;
  padding-top: 45px;
  padding-bottom: 30px;
  ${(props) => props.dark
    && css`
      background: #292929;
      h2 {
        color: #fff;
      }
      h3 {
        color: #fff;
      }
      div {
        color: #979797;
      }
    `}
`;

const SectionTitle = styled.h4`
  font-size: 2em;
  margin: 0.67em 0;
  ${media.xs`
    font-size:1.5em;
  `}
`;


const IndexPage =  ({data}) => (
  <Layout>
    <SEO title="Revolight AB" />
    <Section id="us">
      <SectionTitle></SectionTitle>
      <RevoligtAboutUs />
    </Section>
    <Section id="services">
    <Services1 />
    </Section>
      <Section></Section>

   <Section id="clients">
    <SectionTitle></SectionTitle>
    <Client1 edges={data.allClients.edges}/>
  </Section>

  <Section>
    <SectionTitle></SectionTitle>
      <Certs />
  </Section>

   {/* <Section>
     <SectionTitle></SectionTitle>
     <Testimonial />
   </Section> */}
   
    <Section id="contact">
    <SectionTitle></SectionTitle>
      <Contact />
   </Section>
   <br/>
  </Layout>
);

export default IndexPage

export const pageQuery = graphql`
  query indexQuery {

    allLogos: allImageSharp(
      filter: { original: { src: { regex: "/logo/" } } }
      sort: { fields: original___src }
    ) {
      edges {
        node {
          id
          fixed(height: 160) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    allClients: allImageSharp(
      filter: { original: { src: { regex: "/client/" } } }
      sort: { fields: original___src }
    ) {
      edges {
        node {
          id
          fixed(height: 85) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`;
