
import React from 'react';

import '../css/certs.css';
// import 'bootstrap/dist/css/bootstrap.min.css';


const Certs = () => (
<section className="section-certs services-section">
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-6 col-lg-3">
                <div className="feature-box-1">
                    <div className="icon">
                        <i className="fab fa-aws"></i>
                    </div>
                    <div className="feature-content">
                        <h5>AWS Certified DevOps Engineer</h5>
                        <h6>Professional</h6>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-lg-3">
                <div className="feature-box-1">
                    <div className="icon">
                        <i className="fab fa-aws"></i>
                    </div>
                    <div className="feature-content">
                        <h5>AWS Certified Solutions Architect</h5>
                        <h6>Associate</h6>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-lg-3">
                <div className="feature-box-1">
                    <div className="icon">
                         <i className="fab fa-aws"></i>
                    </div>
                    <div className="feature-content">
                        <h5>AWS Certified SysOps Administrator</h5>
                        <h6>Associate</h6>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-lg-3">
                <div className="feature-box-1">
                    <div className="icon">
                        <i className="fab fa-aws"></i>
                    </div>
                    <div className="feature-content">
                        <h5>AWS Certified Developer</h5>
                        <h6>Associate</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
);

export default Certs;