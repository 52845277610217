import React from 'react';
import Slider  from "react-slick";
import Img from 'gatsby-image';

import "../css/client.css";

const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    fadeIn: false,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

function Client1(props) {
  
  const { edges } = props;
  return (
    <div>
      <Slider {...settings}>
        {edges.map(({ node: item }) => (
        <div key={item.fixed}> <Img fixed={item.fixed} /></div>
        ))}
      </Slider>
  </div>
  );
  
}


export default Client1;
